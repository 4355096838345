<template>
  <div class="heathbanners">
    <div class="block">
    <el-carousel trigger="click" height="100%">
      <el-carousel-item 
      v-for="item in banners" 
      :key="item.id"
       @click.native="$goto('/detailShow',{id:item.id,isremembrance})"
      >
        <!-- <h3 class="small"></h3> -->
        <img style="width:100%; height:100%" :src="item.smallImage+'!1200x480'" :alt="item.title">
      </el-carousel-item>
    </el-carousel>
    <div class="mask"></div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    banners : {
      type: Array,
      default: () => []
    },
	isremembrance:{
		type:Boolean,
		default:false
	  }
  },
  name: 'Heathbanners'
}
</script>

<style lang="less" scoped>
.heathbanners {
  height: 480px !important;
}
.block {
    width: 100%;
    height: 100%;
    position: relative;
    .mask {
      width: 100%;
      height: 40px;
      // background-color: #000000;
      opacity: 0.35;
      position: absolute;
      bottom: 0px;
      z-index: 2;
  }
}
// .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 150px;
//     margin: 0;
//   }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
</style>
<style>
.el-carousel {
    height: 100% !important;
}
.el-carousel__indicators {
    z-index: 3 !important;
}
</style>